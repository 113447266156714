import {
  FC,
  useRef,
  useEffect,
  PropsWithChildren,
  useMemo,
  RefObject,
  Children,
  cloneElement,
  ReactElement,
} from 'react';

export type BackdropClickProps = {
  onClick: () => void;
  excludedElRef?: RefObject<HTMLElement>;
};

export const BackdropClick: FC<PropsWithChildren<BackdropClickProps>> = ({
  onClick,
  excludedElRef = undefined,
  children = undefined,
}) => {
  const ref = useRef<HTMLElement>();
  const onlyChild = Children.only(children) as ReactElement;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const childRef = onlyChild.ref as RefObject<HTMLElement>;
  const hasRef = childRef !== null;
  const child = useMemo(() => (hasRef ? onlyChild : cloneElement(onlyChild, {ref})), [children]);

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      const ancestors: HTMLElement[] = [event.target as HTMLElement];
      while (ancestors[ancestors.length - 1].parentElement) {
        ancestors.push(ancestors[ancestors.length - 1].parentElement as HTMLElement);
      }
      if (
        (ref && ref.current && ancestors.includes(ref.current)) ||
        (excludedElRef && excludedElRef.current && ancestors.includes(excludedElRef.current))
      ) {
        return;
      }
      onClick();
    };

    document.addEventListener('click', handler);
    return () => {
      document.removeEventListener('click', handler);
    };
  }, [children, onClick]);

  return <>{child}</>;
};
