import {Popper} from '@mui/material';
import clsx from 'clsx';
import {FactoryOpts} from 'imask';
import {
  FocusEvent,
  isValidElement,
  KeyboardEvent,
  MouseEvent,
  MouseEventHandler,
  PointerEvent,
  ReactElement,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useIMask} from 'react-imask';
import {twMerge} from 'tailwind-merge';
import {VirtualList} from '../../external/components/VirtualList/VirtualList';
import {Highlight} from '../../external/components/Highlight/Highlight';
import {zIndex} from '../../external/const';
import {Subject, useSubject, useSubjectSource} from '../../external/helpers/Subject';
import {TestableElement} from '../../external/types';
import {BackdropClick} from '../../internal/components/BackdropClick/BackdropClick';
import {Label, LabelProps, LabelSize} from '../../internal/components/Label/Label';
import {Icon, IconProps, IconSize, IconSvg} from '../Icon/Icon';
import {Notification, NotificationSize, NotificationVariant} from '../Notification/Notification';

export enum InputSize {
  SM = 'SM',
  MD = 'MD',
}

export enum InputWidth {
  FULL = 'FULL',
  FIT = 'FIT',
  BASE = 'BASE',
  INITIAL = 'INITIAL',
}

export enum InputType {
  TEXT = 'text',
  NUMBER = 'number',
  STRING_NUMBER = 'string_number',
  PASSWORD = 'password',
}

export const NumericInputTypes = [InputType.NUMBER, InputType.STRING_NUMBER];

export type InputProps<T = string | number> = {
  /** EVENTS */
  onInput?: (output: T) => void;
  onChange?: (output: T) => void;
  onComplete?: (output: T) => void;
  onAutocomplete?: (output: T) => void;
  onAutocompleteToggle?: (isVisible: boolean) => void;
  onIconMouseDown?: () => void;
  onIconClick?: (event: MouseEvent<HTMLSpanElement>, inputEl?: HTMLInputElement) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onMouseDown?: (event: PointerEvent<HTMLInputElement>) => void;
  onClick?: () => void;

  /** Appearance */
  rightIcon?: IconSvg | ReactElement<IconProps>;
  leftIcon?: IconSvg | ReactElement<IconProps>;
  label?: string | ReactElement<LabelProps>;
  isError?: boolean;
  size?: InputSize;
  width?: InputWidth;
  notification?: string;
  alignTextCenter?: boolean;
  noOutline?: boolean;
  noBorder?: boolean;

  /** Generic */
  defaultValue?: T;
  value?: T;
  className?: string;
  wrapperClassName?: string;
  inputClassName?: string;
  placeholder?: string | ReactElement;
  disabled?: boolean;
  required?: boolean;
  type?: InputType;
  min?: number;
  max?: number;
  autofocus?: boolean;
  readonly?: boolean;

  /** Extended */
  mask?: FactoryOpts;
  autocomplete?: string[];
  autocompleteWidth?: InputWidth;
  autocompleteDropUp?: boolean;
  showAutocompleteTooltipValue?: boolean;
  clearable?: boolean;

  focusSubject?: Subject<boolean>;
  testElement?: string;
} & TestableElement;

const getDefaultMask = (mask: object, isNumeric: boolean): object => {
  const ofTypeMask = isNumeric ? {mask: Number, scale: 5} : {mask: String};
  return Object.freeze({...ofTypeMask, ...mask});
};

export const Input = <T extends string | number = string>({
  /** EVENTS */
  onChange = () => undefined,
  onInput = () => undefined,
  onBlur = () => undefined,
  onFocus = () => undefined,
  onComplete = () => undefined,
  onKeyDown = undefined,
  onIconClick = undefined,
  onIconMouseDown = () => undefined,
  onMouseDown = () => undefined,
  onAutocomplete = undefined,
  onAutocompleteToggle = undefined,
  onClick = () => undefined,

  /** Appearance */
  label = '',
  isError = false,
  notification = '',
  size = InputSize.MD,
  width = InputWidth.BASE,
  leftIcon = undefined,
  rightIcon = undefined,
  alignTextCenter = false,
  noOutline = false,
  noBorder = false,

  /** Generic */
  placeholder = '',
  required = false,
  className = undefined,
  wrapperClassName = undefined,
  inputClassName = undefined,
  disabled = false,
  autofocus = false,
  defaultValue = undefined,
  value = undefined,
  type = InputType.TEXT,
  min = undefined,
  max = undefined,
  readonly = false,

  /** Extended */
  mask = {},
  autocomplete = undefined,
  autocompleteWidth = InputWidth.INITIAL,
  showAutocompleteTooltipValue = false,
  clearable = undefined,

  focusSubject = undefined,
  testId = undefined,
  testElement = 'input',
  componentRef = undefined,
}: InputProps<T> & {componentRef?: RefObject<HTMLDivElement>}) => {
  const scrollBoxRef = useRef<HTMLDivElement>(null);
  const suppressBlurEvent = useRef<boolean>(false);
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [_isAutocompleteVisible, _setAutocompleteVisible] = useState<symbol | false>(false);
  const [activeHintIndex, setActiveHintIndex] = useState<number>(-1);
  const isNumeric = useMemo<boolean>(() => NumericInputTypes.includes(type), [type]);

  const isControlledInput = (() => {
    if (defaultValue !== undefined && value !== undefined) {
      throw new Error('Symfonia:brandbook:Input: mutually exclusive parameters');
    }
    if (defaultValue === undefined && value === undefined) {
      console.warn('Symfonia:brandbook:Input: uncontrolled input without "defaultValue"');
    }
    return value !== undefined;
  })();

  const scrollSubject = useSubjectSource<number>();
  const ref = useRef<HTMLDivElement>(null);
  const allowWrite = useRef<boolean>(false);
  const allowOnInput = useRef<boolean>(false);
  const [inputValue, setInputValue] = useState<T>('' as T);

  const [maskConfig, setMaskConfig] = useState<FactoryOpts>(getDefaultMask(mask, isNumeric));
  useEffect(() => {
    const nextMaskConfig = getDefaultMask(mask, isNumeric);
    const shouldChange = JSON.stringify(maskConfig) !== JSON.stringify(nextMaskConfig);

    if (shouldChange) {
      setMaskConfig(nextMaskConfig);
    }
  }, [mask, type]);

  const fixValueForType = (val: number | string) => {
    if (type === InputType.NUMBER) {
      const nextValue = parseFloat(`${val}`.replace(',', '.'));
      return Number.isNaN(nextValue) ? '' : nextValue;
    }
    if (type === InputType.STRING_NUMBER) {
      return `${val}`.replace('.', ',');
    }
    return val;
  };

  const {
    ref: inputRef,
    setUnmaskedValue,
    setTypedValue,
    unmaskedValue,
    maskRef,
  } = useIMask<HTMLInputElement>(maskConfig, {
    onAccept: (maskedValue, {unmaskedValue: currentUnmaskedValue}) => {
      if (isControlledInput === false || allowWrite.current === true) {
        allowWrite.current = false;
        setInputValue(maskedValue as T);
      }

      if (isControlledInput === true && allowWrite.current === false) {
        allowWrite.current = true;
      }

      if (isFocus || allowOnInput.current) {
        allowOnInput.current = false;
        onInput(fixValueForType(currentUnmaskedValue) as T);
      }
    },
    onComplete: (_maskedValue, {unmaskedValue: currentUnmaskedValue}) => {
      onComplete(fixValueForType(currentUnmaskedValue) as T);
    },
  });

  useSubject(focusSubject, nextFocus => {
    if (nextFocus) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
    }
  });

  const isAutocompleteVisible = !!_isAutocompleteVisible;
  const setAutocompleteVisible = (b: boolean) => {
    if (b === true) {
      const nextHindIndex = autocomplete?.findIndex(v => v === inputValue) || -1;
      setActiveHintIndex(nextHindIndex);
      scrollSubject.next(nextHindIndex);
    }
    if (onAutocompleteToggle) {
      onAutocompleteToggle(b);
    }
    _setAutocompleteVisible(b ? Symbol('_isAutocompleteVisible') : false);
  };

  const setIMaskValue = (val: T) => {
    const nextValue: string = (() => {
      if (isNumeric) {
        return `${val}`.replace('.', ',');
      }
      return val as string;
    })();
    setUnmaskedValue(nextValue);
  };

  useEffect(() => {
    if (!inputValue) {
      return;
    }

    setTimeout(() => {
      onInput(maskRef.current?.unmaskedValue as T);
    }, 33);
  }, [maskConfig]);

  const setInputValueFromAutocomplete = (nextValue: string) => {
    allowOnInput.current = true;
    setIMaskValue(nextValue as T);
    onChange(nextValue as T);
    setAutocompleteVisible(false);
    if (onAutocomplete) {
      onAutocomplete(nextValue as T);
    }
  };

  const clearInput = () => {
    allowOnInput.current = true;
    setIMaskValue('' as T);
    onChange('' as T);
    inputRef.current?.focus();
  };

  useEffect(() => {
    const nextValue = isControlledInput ? value : defaultValue;
    const fallbackValue = isNumeric ? 0 : '';
    setIMaskValue((nextValue !== undefined ? nextValue : fallbackValue) as T);
  }, []);

  useEffect(() => {
    if (isControlledInput) {
      if (value === '') {
        setTypedValue('');
      } else {
        setTimeout(() => setIMaskValue(value as T), 5);
      }
    }
  }, [value]);

  useEffect(() => {
    const onChangeEvent = (event: Event) => {
      const nextValue = (event?.target as HTMLInputElement).value;
      onChange(fixValueForType(nextValue) as T);
    };

    if (inputRef.current) {
      inputRef.current.addEventListener('change', onChangeEvent);
    }

    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener('change', onChangeEvent);
      }
    };
  });

  const showClearIcon =
    (clearable === true || (autocomplete !== undefined && clearable !== false)) && unmaskedValue.length > 0;
  const valueInAutocomplete = autocomplete !== undefined && autocomplete.includes(unmaskedValue);

  const styles = {
    component: twMerge(
      clsx(
        'inline-flex',
        {
          'w-full': width === InputWidth.FULL,
          'w-[285px]': width === InputWidth.BASE,
          'w-fit': width === InputWidth.FIT,
        },
        wrapperClassName,
      ),
    ),
    inputBox: twMerge(
      clsx(
        'relative rounded-[8px] flex items-center text-base font-quicksand',
        {
          border: !noBorder,
          'border-none': noBorder,
          'pl-[12px] before:left-0': !alignTextCenter,
          'h-[40px]': size === InputSize.SM,
          'h-[48px]': size === InputSize.MD,
          'border-grey-300 hover:border-primary-400': !isError && !disabled && !isFocus,
          'border-red-500': isError && !disabled,
          'border-primary-400': !isError && !disabled && isFocus,
          'outline outline-[3px] outline-primary-600 before:left-[-1px] before:outline-white before:rounded-[8px] before:absolute before:w-[calc(100%+2px)] before:h-[calc(100%+2px)] before:outline before:outline-[2px]':
            !noOutline && !isError && !disabled && isFocus && !isMouseDown,
          'border-grey-300': disabled,
        },
        className,
      ),
    ),
    input: twMerge(
      clsx(
        'w-full outline-none focus:outline-none caret-black',
        {
          'text-center': alignTextCenter,
          'text-grey-300 placeholder:text-grey-300 bg-transparent': disabled,
          'text-grey-800 placeholder:text-grey-500': !disabled,
          'cursor-default': readonly,
        },
        inputClassName,
      ),
    ),
    label: twMerge(
      clsx('mb-[8px]', {
        'text-grey-400': disabled,
      }),
    ),
    icon: clsx('select-none mr-[12px] grow-0 shrink-0', {
      'cursor-pointer': !!onIconClick,
      'filter-primary-500': !disabled && !isError,
      'filter-red-500': !disabled && isError,
      'filter-grey-300 cursor-default pointer-events-none': disabled,
    }),
    deleteIcon: 'mr-[12px] grow-0 shrink-0 filter-grey-500 cursor-pointer',
    autocomplete: clsx(
      'max-h-[340px] mt-[8px] p-[8px] flex bg-white border rounded-lg font-quicksand flex-col border-grey-300 shadow overflow-x-hidden overflow-y-auto',
      {
        'w-full': width === InputWidth.FULL,
        'w-[285px]': width === InputWidth.BASE,
        'w-fit': width === InputWidth.FIT,
      },
    ),
    autocompleteElement: (hintIndex: number) =>
      clsx('group shrink-0 px-[16px] flex items-center relative text-black', {
        'h-[40px]': size === InputSize.SM,
        'h-[48px]': size === InputSize.MD,
        'bg-primary-100 active:bg-primary-500 active:text-white cursor-pointer rounded-[8px]':
          hintIndex === activeHintIndex,
      }),
  };

  const handleInputFocus = (event: FocusEvent<HTMLInputElement>) => {
    setIsFocus(true);
    setAutocompleteVisible(true);
    onFocus(event);
  };

  const handleInputBlur = (event: FocusEvent<HTMLInputElement>) => {
    setIsMouseDown(false);
    setIsFocus(false);
    if (suppressBlurEvent.current === false) {
      onBlur(event);
      requestAnimationFrame(() => setAutocompleteVisible(false));
    }
  };

  const handleMouseDown = (event: PointerEvent<HTMLInputElement>) => {
    setIsMouseDown(true);
  };

  const handleIconClick: MouseEventHandler<HTMLSpanElement> = event => {
    const nextIsMouseDown = !!(onIconClick && onIconClick(event, inputRef.current!));

    if (nextIsMouseDown) {
      setIsMouseDown(nextIsMouseDown);
    }
  };

  const renderNotification = () => (
    <Notification
      text={notification}
      variant={!disabled && isError ? NotificationVariant.ERROR : NotificationVariant.INFO}
      size={NotificationSize.SM}
      className="mt-[8px]"
    />
  );

  const renderPopper = () => {
    const popoverWidth = (() => {
      if (autocompleteWidth === InputWidth.FULL) {
        return '100%';
      }
      if (autocompleteWidth === InputWidth.BASE) {
        return '285px';
      }

      if (autocompleteWidth === InputWidth.INITIAL) {
        return ref?.current ? `${ref.current.getBoundingClientRect().width}px` : '100%';
      }
      return 'fit-content';
    })();

    const isOpen =
      autocomplete !== undefined &&
      autocomplete &&
      autocomplete.length > 0 &&
      isAutocompleteVisible &&
      !valueInAutocomplete;

    return (
      <Popper
        className={`z-[${zIndex.aboveModal}]`}
        slotProps={{
          root: {
            style: {
              width: popoverWidth,
              background: 'transparent',
              boxShadow: 'none',
            },
          },
        }}
        open={isOpen}
        anchorEl={ref.current}
        placement="bottom"
        onMouseEnter={() => {
          suppressBlurEvent.current = true;
        }}
        onMouseLeave={() => {
          suppressBlurEvent.current = false;
        }}
        onClick={() => {
          suppressBlurEvent.current = false;
        }}
      >
        {autocomplete !== undefined && (
          <BackdropClick excludedElRef={ref} onClick={() => setAutocompleteVisible(false)}>
            <div className={styles.autocomplete} ref={scrollBoxRef}>
              <VirtualList scrollToIndex={scrollSubject} parentRef={scrollBoxRef} rowHeightPx={48}>
                {autocomplete?.map((hintEl, hintIndex) => (
                  <span title={showAutocompleteTooltipValue ? hintEl : undefined}>
                    <Highlight
                      className={styles.autocompleteElement(hintIndex)}
                      key={hintEl}
                      onClick={() => setInputValueFromAutocomplete(hintEl)}
                      text={hintEl}
                      phrase={`${inputValue || ''}`}
                      highlightClassName={clsx('bg-primary-50', {
                        'bg-transparent': hintIndex === activeHintIndex,
                      })}
                      onMouseEnter={() => setActiveHintIndex(hintIndex)}
                      onMouseLeave={() => setActiveHintIndex(-1)}
                    />
                  </span>
                ))}
              </VirtualList>
            </div>
          </BackdropClick>
        )}
      </Popper>
    );
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (onKeyDown) {
      onKeyDown(event);
    }

    if (autocomplete !== undefined && !isAutocompleteVisible) {
      setAutocompleteVisible(true);
    }

    if (event.code === 'ArrowUp' && autocomplete !== undefined && isAutocompleteVisible) {
      setActiveHintIndex(prevState => {
        const nextState = prevState > 0 ? prevState - 1 : autocomplete.length - 1;
        scrollSubject.next(nextState);
        return nextState;
      });
    }

    if (event.code === 'ArrowDown' && autocomplete !== undefined && isAutocompleteVisible) {
      setActiveHintIndex(prevState => {
        const nextState = prevState < autocomplete.length - 1 ? prevState + 1 : 0;
        scrollSubject.next(nextState);
        return nextState;
      });
    }

    if (event.code === 'Enter' && isAutocompleteVisible) {
      if (autocomplete !== undefined && activeHintIndex !== -1) {
        setInputValueFromAutocomplete(autocomplete[activeHintIndex]);
      }
    }
  };

  const inputProps = {
    onMouseDownCapture: handleMouseDown,
    onFocus: handleInputFocus,
    onBlur: handleInputBlur,
    onKeyDown: handleKeyDown,
    onMouseDown: onMouseDown,
    onClick: onClick,
    disabled: disabled,
    placeholder: typeof placeholder === 'string' ? placeholder : undefined,
    className: styles.input,
    type: [InputType.TEXT, InputType.PASSWORD].includes(type) ? type : InputType.TEXT,
    min: min,
    max: max,
    autoFocus: autofocus,
    readOnly: readonly,
  };

  const displayTitle = inputRef.current ? inputRef.current.scrollWidth > inputRef.current.offsetWidth : false;
  return (
    <div ref={componentRef} className={styles.component} data-test-element={testElement} data-testid={testId}>
      <div className="flex flex-col w-full">
        {label &&
          (isValidElement(label) ? (
            label
          ) : (
            <Label
              className={styles.label}
              text={label}
              isRequired={required}
              size={size === InputSize.MD ? LabelSize.MD : LabelSize.SM}
            />
          ))}
        <div className={styles.inputBox} ref={ref}>
          {isValidElement(placeholder) && (
            <div className="pointer-events-none absolute flex items-center rounded-[8px] bg-white top-[2px] bottom-[2px] left-[2px] right-[2px]">
              {placeholder}
            </div>
          )}
          {leftIcon &&
            (isValidElement(leftIcon) ? (
              leftIcon
            ) : (
              <Icon svg={leftIcon} size={IconSize.MD} className={styles.icon} onClick={handleIconClick} />
            ))}

          <input
            tabIndex={0}
            {...inputProps}
            ref={inputRef}
            value={inputValue}
            title={displayTitle ? unmaskedValue : undefined}
            onChange={() => undefined /* sic */}
          />
          {showClearIcon && (
            <Icon
              svg={IconSvg.CLEAR}
              size={IconSize.MD}
              className={styles.deleteIcon}
              onClick={e => {
                e.stopPropagation();
                clearInput();
              }}
            />
          )}
          {rightIcon &&
            (isValidElement(rightIcon) ? (
              rightIcon
            ) : (
              <Icon
                svg={rightIcon}
                size={IconSize.MD}
                className={styles.icon}
                onMouseDown={onIconMouseDown}
                onClick={handleIconClick}
              />
            ))}
        </div>
        {notification && renderNotification()}
        {renderPopper()}
      </div>
    </div>
  );
};
